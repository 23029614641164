import * as types from "./types";
import _ from "lodash";
export default (state = [], action) => {
  switch (action.type) {
    case types.FETCH_EMPLOYEE_TO_PROCESS_PAYROLL:
      return { ..._.mapKeys(action.payload, "key") };
    case types.FETCH_EMPLOYEE_TO_SEND_EMAIL:
      return { ..._.mapKeys(action.payload, "key") };
    // return { ...state, [action.payload]: action.payload };
    default:
      return state;
  }
};
export const PayrollVoucher = (state = [], action) => {
  switch (action.type) {
    case types.FETCH_PAYROLL_VOUCHERS:
      return { ..._.mapKeys(action.payload, "key") };
    case types.FETCH_PAYROLL_VOUCHER:
      return { ...state, ..._.mapKeys(action.payload, "key") };
    case types.DELETE_PAYROLL_VOUCHER:
      return _.omit(state, action.payload);
    default:
      return state;
  }
};
export const PayrollMonthYear = (state = [], action) => {
  switch (action.type) {
    case types.PROCESS_PAYROLL:
      return { ...state, ..._.mapKeys(action.payload, "value") };
    case types.SEND_EMAIL:
      return { ...state, ..._.mapKeys(action.payload, "value") };
    case types.FETCH_PAYROLL_MONTH_YEAR:
      return { ..._.mapKeys(action.payload, "value") };
    case types.UPDATE_PAYROLL_HEADER:
      return { ...state, ..._.mapKeys(action.payload, "value") };
    case types.DELETE_PAYROLL_HEADER:
      return _.omit(state, action.payload);
    default:
      return state;
  }
};
export const PayrollHeaderVoucher = (state = [], action) => {
  switch (action.type) {
    case types.FETCH_PAYROLL_HEADER_VOUCHER:
      return { ..._.mapKeys(action.payload, "Id") };

    case types.FETCH_PAYROLL_HEADER_VOUCHER_COMMISSION:
      let x = state[action.payload.Id];
      x = {
        ...x,
        ...action.payload,
      };
      let y = [];
      y.push(x);
      console.log(x);
      // return { ...state, [action.payload.Id]: x };
      return { ..._.mapKeys(y, "Id") };

    case types.EDIT_PAYROLL_HEADER_VOUCHER:
      return { ..._.mapKeys(action.payload, "Id") };

    default:
      return state;
  }
};
export const PayrollAllowanceDeductions = (state = [], action) => {
  switch (action.type) {
    case types.FETCH_PAYROLL_ALLOWANCE_DEDUCTION:
      // return { ...state, [action.payload]: action.payload };
      return { [action.payload]: action.payload };
    default:
      return state;
  }
};
