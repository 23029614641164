import React, { Suspense } from "react";
import { Switch, Route, Router } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loadable from "react-loadable";
import "../../node_modules/font-awesome/scss/font-awesome.scss";
import Loader from "./layout/Loader/index";
import Aux from "../hoc/_Aux";
import { PrivateRoute } from "../Authentication/Auth";
import routes from "../route";
import history from "../history";

const AdminLayout = Loadable({
  loader: () => import("./layout/AdminLayout"),
  loading: Loader,
});
const App = () => {
  const { IsLoading } = useSelector((state) => state.ui_Ids);
  const dispatch = useDispatch();

  const menu = routes.map((route, index) => {
    return route.component ? (
      <Route
        key={index}
        path={route.path}
        exact={route.exact}
        name={route.name}
        render={(props) => <route.component {...props} />}
      />
    ) : null;
  });
  return (
    <Aux>
      {IsLoading && <Loader />}
      <Router history={history}>
        <Suspense fallback={<Loader />}>
          <Switch>
            {menu}
            <PrivateRoute>
              <Route path="/" component={AdminLayout} />
            </PrivateRoute>
          </Switch>
        </Suspense>
      </Router>
    </Aux>
  );
};

export default App;
