export const FETCH_EMPLOYEE_TO_PROCESS_PAYROLL =
  "FETCH_EMPLOYEE_TO_PROCESS_PAYROLL";
export const FETCH_EMPLOYEE_TO_SEND_EMAIL = "FETCH_EMPLOYEE_TO_SEND_EMAIL";
export const PROCESS_PAYROLL = "PROCESS_PAYROLL";
export const SEND_EMAIL = "SEND_EMAIL";
export const FETCH_PAYROLL_VOUCHERS = "FETCH_PAYROLL_VOUCHERS";
export const DELETE_PAYROLL_VOUCHER = "DELETE_PAYROLL_VOUCHER";
export const FETCH_PAYROLL_VOUCHER = "FETCH_PAYROLL_VOUCHER";
export const FETCH_PAYROLL_MONTH_YEAR = "FETCH_PAYROLL_MONTH_YEAR";
export const UPDATE_PAYROLL_HEADER = "UPDATE_PAYROLL_HEADER";
export const DELETE_PAYROLL_HEADER = "DELETE_PAYROLL_HEADER";
export const FETCH_PAYROLL_HEADER_VOUCHER = "FETCH_PAYROLL_HEADER_VOUCHER";
export const FETCH_PAYROLL_HEADER_VOUCHER_COMMISSION =
  "FETCH_PAYROLL_HEADER_VOUCHER_COMMISSION";
export const EDIT_PAYROLL_HEADER_VOUCHER = "EDIT_PAYROLL_HEADER_VOUCHER";
export const FETCH_PAYROLL_ALLOWANCE_DEDUCTION =
  "FETCH_PAYROLL_ALLOWANCE_DEDUCTION";
