import _ from "lodash";
import * as type from "./types";
export default (state = {}, action) => {
  switch (action.type) {
    case type.FETCH_REQUEST_TRAVELS:
      return { ..._.mapKeys(action.payload, "Id") };
    case type.FETCH_REQUEST_TRAVEL:
      return { ...state, [action.payload.Id]: action.payload };
    case type.EDIT_REQUEST_TRAVEL:
      return { ...state, [action.payload.Id]: action.payload };

    default:
      return state;
  }
};
