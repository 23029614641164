import _ from "lodash";
import * as type from "./types";
export default (state = {}, action) => {
  switch (action.type) {
    case type.CREATE_ATTENDANCE_CHECK_IN_OUT:
      return { ...state, [action.payload.Id]: action.payload };
    // case type.CREATE_ATTENDANCE_CHECK_IN_OUT:
    // return action.payload;
    // case type.FETCH_ATTENDANCE_CHECK_IN_OUT:
    // return action.payload;
    case type.FETCH_ATTENDANCE_CHECK_IN_OUT_BY_ID:
      return _.mapKeys(action.payload, "Id");
    case type.EDIT_ATTENDANCE_CHECK_IN_OUT:
      return { ...state, [action.payload.Id]: action.payload };
    case type.DELETE_ATTENDANCE_CHECK_IN_OUT:
      return _.omit(state, action.payload);
    default:
      return state;
  }
};
